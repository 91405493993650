
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { bus } from "@/main";
import { formatDate } from "@/utils/formatters";

@Component({
    components: {
        documentCard: () => import("@/components/document/card.vue"),
    },
})
export default class DocumentFile extends Vue {
    @Prop() document!: Document;
    @Prop({ default: false }) canGroupSelect!: boolean;
    @Prop() mode!: "partner" | "admin" | "client";
    @Prop({ default: "Hubr" }) theme!: "Hubr" | "My Hubr";

    /**
     * -----------------
     * Private variables
     * -----------------
     */

    bus = bus;

    formatDate = formatDate;

    selected = false;

    /**
     * -----------------
     * File getters
     * -----------------
     */

    get media() {
        return this.document.media[0];
    }

    get wrappedName() {
        // @ts-ignore
        const mediaQuery = this.$root.mediaQuery;
        let name: string = this.document.name;
        let size = 16;
        switch (mediaQuery) {
            case "sm":
                size = 30;
                break;
            case "md":
            case "lg":
                size = 20;
                break;
            case "xl":
                size = 26;
                break;
            case "2xl":
                size = 20;
                break;
            case "3xl":
            case "4xl":
                size = 26;
                break;
        }

        if (name.length < size) {
            return name;
        }

        return name.substring(0, size / 2 - 1) + " ... " + name.substring(name.length - size / 2 - 1, name.length);
    }

    get isImage() {
        return this.media.mime_type.includes("image");
    }

    get isPdf() {
        return this.media.mime_type.includes("pdf");
    }

    get isVideo() {
        return this.media.mime_type.includes("video");
    }

    /**
     *  Toggle the current file selection on or off
     *
     *  @returns void
     */
    toggleSelect() {
        if (this.selected) {
            return this.deselectFile();
        }

        return this.selectFile();
    }

    /**
     *  Select the current file
     *
     *  @returns void
     */
    selectFile() {
        if (this.selected) {
            return;
        }

        this.selected = true;

        this.handleFileSelected(this.document);
    }

    /**
     *  Select the current file
     *
     *  @returns void
     */
    deselectFile() {
        if (!this.selected) {
            return;
        }

        this.selected = false;

        this.handleFileDeSelected(this.document);
    }

    /**
     * -----------------
     * File emitters
     * -----------------
     * @file-selected: Document
     * @file-deselected: Document
     */

    @Emit("file-selected")
    handleFileSelected(document: Document) {
        return document;
    }

    @Emit("file-deselected")
    handleFileDeSelected(document: Document) {
        return document;
    }
}
